<template>
  <div>
      <trev-report-add :editReport="editReport"></trev-report-add>
  </div>
</template>

<script>
import trevReportAdd from '../../../../components/reports/sql/trev-report-add.vue'
export default {
  components: { trevReportAdd },
  async created () {
    if(this.$route.params.reportId){
      //in edit mode

      this.isEdit = true;
      this.editId = this.$route.params.reportId;
      this.editReport = await this.getReport();
    }
  },
  methods: {
    async getReport() {
      return await this.$courier.ReportSQL.getById(this.editId);
    }
  },
  data() {
    return {
      isEdit: false,
      editId: '',
      editReport: {}
    }
  },
}
</script>

<style>

</style>