<template>
  <div>
    <v-row>
      <v-col cols="4">
        <v-text-field label="Name" v-model="report.name"> </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-expand-transition>
          <v-card class="mt-2" v-show="!reportError">
            <v-toolbar elevation="1">
              <v-toolbar-title>
                <v-icon> mdi-database-search-outline </v-icon>
                Results {{ reportResults.length }}
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text v-if="reportResults.length > 0">
              <v-data-table :headers="getHeaders" :items="reportResults">
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-expand-transition>
        <v-card class="mt-2" v-show="reportError">
          <v-toolbar elevation="1">
            <v-toolbar-title>
              <v-icon> mdi-alert-circle-outline </v-icon>
              Error
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            {{ reportError }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-card>
          <v-toolbar elevation="1">
            <v-toolbar-title>
              <v-icon> mdi-database-import-outline </v-icon>
              Variables
            </v-toolbar-title>
            <v-spacer> </v-spacer>
            <v-btn icon @click="showAddVariable = true">
              <v-icon> mdi-plus </v-icon>
            </v-btn>
          </v-toolbar>
          <v-list>
            <v-list-item v-if="report.variables.length == 0">
              Nothing here
            </v-list-item>
            <v-list-item
              v-for="variable in report.variables"
              :key="variable.name"
            >
              <v-list-item-icon>
                <v-icon> mdi-lightbulb-variant-outline </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ variable.name }} : {{ variable.textReplace }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ variable.value }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon @click="removeVariable(variable)">
                  <v-icon color="danger">mdi-delete</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <v-toolbar elevation="1">
            <v-toolbar-title>
              <v-icon> mdi-database-edit-outline </v-icon>
              Editor
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-row>
              <v-col class="pt-0 pb-0">
                <v-btn text color="grey" @click="runReport"
                  ><v-icon class="mr-1" small>mdi-play</v-icon>RUN</v-btn
                >
                <v-btn text color="grey"
                  ><v-icon class="mr-1" small>mdi-square</v-icon>CANCEL
                  QUERY</v-btn
                >
                <v-btn text color="grey" @click="saveReport"
                  ><v-icon class="mr-1" small>mdi-content-save</v-icon>SAVE
                  QUERY</v-btn
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col class="">
                <v-textarea outlined label="Your query" v-model="report.sql">
                </v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showAddVariable"
      max-width="500px"
      @click:outside="cancelAddVariable"
    >
      <v-card>
        <v-card-title> Add New Variable </v-card-title>
        <v-card-text>
          <v-text-field label="Name" v-model="newVariable.name"> </v-text-field>
          <v-text-field
            label="Replacement Text"
            v-model="newVariable.textReplace"
          >
          </v-text-field>
          <v-select
            label="Value"
            :items="variableTypes"
            v-model="newVariableType"
          >
          </v-select>
          <v-text-field
            label="Custom Value"
            v-model="newVariable.value"
            v-show="newVariableType == 'Custom'"
          >
          </v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="cancelAddVariable"> Cancel </v-btn>
          <v-btn color="primary" @click="addVariable"> Add Variable </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import trevCoolList from "../../tables/trev-cool-list.vue";
export default {
  components: { trevCoolList },
  watch: {
    "newVariable.value"() {
      this.newVariable.isCustom = this.newVariable.value == "Custom";
    },
  },
  props: {
    editReport: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    getHeaders() {
      return Object.keys(this.reportResults[0]).map((x) => ({
        text: x,
        value: x,
      }));
    },
  },
  watch: {
    editReport: {
      handler() {
        {
          if (this.editReport) {
            this.editReport.variables = [];
            this.report = this.editReport;
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    saveReport() {
      if (this.$route.params.reportId) {
        this.$courier.ReportSQL.updateById(
          this.$route.params.reportId,
          this.editReport
        ).then(() => {
          this.$router.push({
            name: "Reports",
          });
        });
      } else {
        this.$courier.ReportSQL.add(this.report).then(() => {
          this.$router.push({
            name: "Reports",
          });
        });
      }
    },
    runReport() {
      this.$courier.ReportSQLActions.RunReport(this.report).then((x) => {
        x.json().then((jsonData) => {
          if (jsonData.error) {
            this.reportError = jsonData.error;
          } else {
            if (jsonData.payload && jsonData.totalCount) {
              this.reportResults = jsonData.payload;
              this.reportError = "";
            }
          }
        });
      });
    },
    addVariable() {
      this.newVariable.value =
        this.newVariableType == "Custom"
          ? this.newVariable.value
          : this.newVariableType;
      this.report.variables.push({
        name: this.newVariable.name,
        value: this.newVariable.value,
      });

      this.cancelAddVariable();
    },
    cancelAddVariable() {
      this.showAddVariable = false;
      this.newVariableType = "";
      this.newVariable.name = "";
      this.newVariable.value = "";
    },
    removeVariable(variable) {
      this.report.variables = this.report.variables.filter(
        (x) => x != variable
      );
    },
  },
  data() {
    return {
      reportError: "",
      reportResults: [],
      report: {
        name: "",
        variables: [],
        sql: "",
      },
      newVariable: {
        name: "",
        textReplace: "",
        value: "",
        isCustom: false,
      },
      newVariableType: "",
      variableTypes: [
        "Custom",
        "Last Month",
        "Next Month",
        "Start of this Month",
        "End of this Month",
      ],
      showAddVariable: false,
    };
  },
};
</script>

<style>
</style>